<template>
  <div class="main p-35">
    <div class="cut_off p-b-10">
      <p class="font24 color-ffa">购买课程</p>
    </div>
    <div class="flex-wrap">
      <div
        class="mc-li m-r-20 m-b-20"
        v-for="(item, inx) in coveList"
        :key="inx"
      >
        <div class="course-main hand marc20 over-now" @click="btnDetail(item)">
          <div class="flex-cen po-rela m-img">
            <img :src="item.coverImg" alt="课程图片" />
            <div class="flex-cen mi-mask">
              <img class="img40" src="@/assets/images/icon_16.png" alt="" />
            </div>
          </div>
          <div class="m-t-10 pad10 font20 color333 weight m-title over-now">
            {{ item.title }}
          </div>
          <template v-if="isShowDesc">
            <div class="m-t-8 pad20 font14 color999">
              {{ item.teacher }}|{{ item.label }}
            </div>
            <div class="flex-between m-t-8 pad20 font12 colorccc">
              <div class="color22b" v-if="free">免费观看</div>
              <div class="" v-else>
                <div class="font14 colorff9 weight">¥ {{ item.salePrice }}</div>
                <!-- <div class="m-l-8 text-del">¥ {{ item.original }}</div>/ -->
              </div>
              <div class="">
                <div class="goBtn">去学习</div>
                <!-- <span class="m-r-6">222222</span>
            <img class="img12" src="@/assets/images/icon_28.png" alt="" /> -->
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="text-c" v-if="coveList.length != 0">
      <Page
        @on-change="change"
        :total="total"
        :page-size="per_page"
        :current="current_page"
        prev-text="上一页"
        next-text="下一页"
      />
      <slot>
        <span class="ml20"
          >共有 <span class="co36">{{ total }}</span> 条数据</span
        >
      </slot>
    </div> -->
    <div class="text-c" v-if="coveList.length == 0">
      <img src="../assets/images/img_7.png" alt="" />
      <p class="text-c">暂无购买记录</p>
    </div>
  </div>
</template>

<script>
import { payRecord } from "@/api/user";
export default {
  data() {
    return {
      total: 0, //数据总数
      order_page: 1, //页码
      current_page: 1, //当前页码
      per_page: 0, //每页显示条数
      last_page: 0, //最后一页
      user: {},
      isShowDesc: true,
      free: false,
      coveList: [],
    };
  },
  created() {},
  components: {},
  mounted() {
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
    this.getreList();
  },
  methods: {
    btnDetail(item) {
      this.$router.push({
        path: "/CourseDetail",
        query: item,
      });
    },

    change(v) {
      this.order_page = v;
      this.getreList();
    },
    getreList() {
      payRecord({
        user_id: this.user.id,
        pageNum: this.order_page,
        pageSize: 10,
      }).then((res) => {
        this.coveList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 561px;
  overflow: auto;
  background: #ffffff;
  .cut_off {
    border-bottom: 1px solid #ffa54b;
  }
  .course-main {
    width: 264px;

    .m-img {
      width: 264px;
      height: 165px;
      border-radius: 6px;
      overflow: hidden;
      background: #f2f2f2;
      > img {
        width: 100%;
        height: 100%;
      }
      .mi-mask {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      .m-title {
        color: #ffa54b;
      }
      .mi-mask {
        opacity: 1;
      }
    }
    .goBtn {
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      width: 100px;
      height: 30px;
      background: #ff9905;
      box-shadow: 0px 2px 4px 0px rgba(201, 101, 0, 0.2);
      border-radius: 15px;
    }
  }
}
</style>